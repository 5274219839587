import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`12th August 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now send an HTTP request to an absolute URI, which means you don't need to create different `}<inlineCode parentName="p">{`HttpClient`}</inlineCode>{`s for different hosts. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1143"
          }}>{`#1143`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1343"
          }}>{`#1343`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1961"
          }}>{`#1961`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpClient client = HttpClient.of(); // No base URI specified.
client.get("http://foo.com").aggregate().join();
client.execute(RequestHeaders.of(HttpMethod.GET, "http://bar.com")).aggregate().join();

HttpClient client = HttpClient.of("http://baz.com"); // Base URI specified.
client.get("/index.html").aggregate().join();
client.get("http://qux.com").aggregate().join(); // Can override the base URI.
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`HealthCheckedEndpointGroup`}</inlineCode>{` has been revamped and now supports long-polling when used with Armeria's `}<inlineCode parentName="p">{`HealthCheckService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1948"
          }}>{`#1948`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1977"
          }}>{`#1977`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1982"
          }}>{`#1982`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Long-polling support enables `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` to detect the server health changes immediately with much fewer number of health check requests.`}</li>
          <li parentName="ul">{`Long-polling support is auto-detected based on a special HTTP header `}<inlineCode parentName="li">{`armeria-lphc`}</inlineCode>{`, so it is fully backward-compatible with ordinary non-Armeria health check services.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EndpointGroup group =
    HealthCheckedEndpointGroup.of(
        new StaticEndpointGroup(Endpoint.of("foo.com", 8080),
                                Endpoint.of("bar.com", 8080)),
        "/internal/l7check");
EndpointGroupRegistry.register("myGroup", group,
                               EndpointSelectionStrategy.WEIGHTED_ROUND_ROBIN);
HttpClient client = HttpClient.of("http://group:myGroup");
client.get("/").aggregate().join();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now send a delayed response easily using `}<inlineCode parentName="p">{`HttpResponse.delayed()`}</inlineCode>{`, which may be useful when simulating a slow server. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1935"
          }}>{`#1935`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server = new ServerBuilder()
    .service("/delayed", (ctx, req) -> HttpResponse.delayed(HttpResponse.of(200),
                                                            Duration.ofSeconds(3)))
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now write a mock HTTP server using `}<inlineCode parentName="p">{`MockWebServerExtension`}</inlineCode>{` and JUnit 5. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1884"
          }}>{`#1884`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1935"
          }}>{`#1935`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class MyTest {
    @RegisterExtension
    static MockWebServerExtension server = new MockWebServerExtension();

    @Test
    void test() {
        server.enqueue(HttpResponse.of(200));
        HttpResponse actualRes = HttpClient.of(server.httpUri("/")).get("/");
        assertThat(actualRes.aggregate().join().status().code()).isEqualTo(200);
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AsyncCloseable`}</inlineCode>{` has been added to provide an asynchronous close operation. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1948"
          }}>{`#1948`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class IAmCloseable implements AutoCloseable, AsyncCloseable {
    @Override
    public CompletableFuture<?> closeAsync() {
        ...
    }

    @Override
    public void close() {
        closeAsync().join();
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify an `}<inlineCode parentName="p">{`EventExecutor`}</inlineCode>{` when using `}<inlineCode parentName="p">{`HttpResponse.from()`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1937"
          }}>{`#1937`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now suppress the false warnings from `}<inlineCode parentName="p">{`RequestContextCurrentTraceContext`}</inlineCode>{` by using `}<inlineCode parentName="p">{`setCurrentThreadNotRequestThread()`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1971"
          }}>{`#1971`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1980"
          }}>{`#1980`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`For example, you could prevent warnings from the administrative threads controlled by `}<inlineCode parentName="p">{`ThreadFactory`}</inlineCode>{` like the following:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`ThreadFactory factory = (runnable) -> new Thread(new Runnable() {
    @Override
    public void run() {
        RequestContextCurrentTraceContext.setCurrentThreadNotRequestThread(true);
        runnable.run();
    }

    @Override
    public String toString() {
        return runnable.toString();
    }
});
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLog.responseCause()`}</inlineCode>{` is now recorded correctly for client requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1977"
        }}>{`#1977`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RetryingClient`}</inlineCode>{` now respects the `}<inlineCode parentName="li">{`Endpoint`}</inlineCode>{` selection order, which was broken since 0.89.0. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1973"
        }}>{`#1973`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1974"
        }}>{`#1974`}</a></li>
      <li parentName="ul">{`The health checked requests sent by `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` are now sent at the correct interval, even if an endpoint is not responsive. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1948"
        }}>{`#1948`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`ClosedPublisherException`}</inlineCode>{` is not raised anymore when `}<inlineCode parentName="li">{`HttpResponse`}</inlineCode>{` is aborted by the client who issued the request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1962"
        }}>{`#1962`}</a></li>
      <li parentName="ul">{`Armeria gRPC client now sends the `}<inlineCode parentName="li">{`TE`}</inlineCode>{` header, whose absence caused interoperability issues with some gRPC servers, such as Python gRPC server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1963"
        }}>{`#1963`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1965"
        }}>{`#1965`}</a></li>
      <li parentName="ul">{`Armeria HTTP client does not send more than two `}<inlineCode parentName="li">{`Host`}</inlineCode>{` headers for HTTP/1 anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1942"
        }}>{`#1942`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` now waits until the initial `}<inlineCode parentName="li">{`Endpoint`}</inlineCode>{`s are available from its delegate group. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1940"
        }}>{`#1940`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HealthCheckedEndpointGroupBuilder.retryInterval()`}</inlineCode>{` has been un-deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1948"
        }}>{`#1948`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HealthCheckedEndpointGroupBuilder.healthCheckPort()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`port()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1948"
        }}>{`#1948`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`CircuitBreakerBuilder.circuitBreakerMapping()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`mapping()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1970"
        }}>{`#1970`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HttpHealthCheckedEndpointGroup`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`The old `}<inlineCode parentName="li">{`HealthCheckedEndpointGroup`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`AbstractHealthCheckedEndpointGroup`}</inlineCode>{`, and is now extensible enough for you to implement your own health-checking mechanism, such as sending a gRPC/Thrift call.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Caffeine 2.7.0 -> 2.8.0`}</li>
      <li parentName="ul">{`fastutil 8.2.3 -> 8.3.0`}</li>
      <li parentName="ul">{`Project Reactor 3.2.10 -> 3.2.11`}</li>
      <li parentName="ul">{`Retrofit 2.6.0 -> 2.6.1`}</li>
      <li parentName="ul">{`RxJava 2.2.10 -> 2.2.11`}</li>
      <li parentName="ul">{`SLF4J 1.7.26 -> 1.7.27`}</li>
      <li parentName="ul">{`Spring Boot 2.1.6 -> 2.1.7, 1.5.21 -> 1.5.22`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'dawnbreaks', 'ikhoon', 'imasahiro', 'minwoox', 'mpaltun', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      